import { styled } from 'styled-components'
import { Article } from '../../models/article'
import Text from '../Text'
import { Link } from 'react-router-dom'
import {
  flex_col,
  graylight,
  grayverylight,
  primary,
  ralewayFont,
  white,
} from '../../styles/GlobalStyles'
import { useEffect, useState } from 'react'
import { useAuthors } from '../../hooks/useIndexedAuthors'
import { Author } from '../../models/author'
import { AnimatePresence, motion } from 'framer-motion'

interface ArticleCardProps {
  article: Article
}
const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
  const [author, setAuthor] = useState<Author | undefined>()
  const { authors } = useAuthors()
  useEffect(() => {
    setAuthor(authors.get(article.authorAlias))
  }, [article])
  return (
    <CardContainer
      initial="initial"
      whileHover="hover"
      to={`/post/${article.slug}`}
    >
      <AnimatePresence>
        <ArticleThumbnail
          src={article.thumbnail}
          exit={{ opacity: 0 }}
          variants={image}
        ></ArticleThumbnail>
      </AnimatePresence>
      <ArticleTitle as={motion.h5} variants={content}>
        {article.title}
      </ArticleTitle>
      <ArticleDetails variants={details} transition={{ duration: 0 }}>
        <ArticleAuthor>{author?.name}</ArticleAuthor>
        <ArticlePublished>{article.publishedDate}</ArticlePublished>
      </ArticleDetails>
      <ArticleContent variants={content} transition={{ duration: 0 }}>
        {article.description}l view of money as a means to an end, i propose a
        deeper connection between time, money, and our overall well-being. whats
        the true value of my time? is every hour i spend truly contributing to a
        life path that i find meaningful? by examining this relationship, i can
        make conscious decisions about how to invest both my time and money
      </ArticleContent>
    </CardContainer>
  )
}
export default ArticleCard

const content = {
  initial: { opacity: 1 },
  hover: {
    opacity: [0, 1],
    transition: { delay: 0.2, duration: 0.5, ease: 'easeIn' },
  },
}
const details = {
  initial: { opacity: 0, display: 'none' },
  hover: {
    opacity: 1,
    display: 'flex',
    transition: { delay: 0.2, duration: 0.5, ease: 'easeIn' },
  },
}
const image = {
  initial: { opacity: 1 },
  hover: {
    opacity: 0,
    display: 'none',
    transition: { duration: 0.2, ease: 'easeIn' },
  },
}
const ArticleThumbnail = styled(motion.img)`
  object-fit: cover;
  height: 130px;
`
const ArticleTitle = styled(Text)`
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${graylight};
  line-height: 1.4;
`
const ArticleDetails = styled(motion.div)`
  justify-content: space-between;
  color: ${grayverylight};
`
const ArticleAuthor = styled.span``
const ArticlePublished = styled.span``
const ArticleContent = styled(motion.div)`
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${grayverylight};
  ${ralewayFont}
  line-height: 1.25;
  max-height: 70px;
  transition: all 0.3s;
  transition-delay: 0.2s;

  @media (max-width: 768px) {
    -webkit-line-clamp: 8;
    max-height: 140px;
  }
`

const CardContainer = styled(motion(Link))`
  ${flex_col}
  height: 100%;
  min-height: 320px;
  row-gap: 10px;
  padding: 20px;
  user-select: none;
  border: 1px solid ${graylight};
  transition: border-color 0.5s ease-in;
  &:hover {
    border-color: ${primary};
  }
  &:hover ${ArticleTitle} {
    color: ${white};
  }
  &:hover ${ArticleContent} {
    color: ${graylight};
    max-height: 175px;
    -webkit-line-clamp: 10;
    @media (max-width: 768px) {
      -webkit-line-clamp: 15;
      max-height: 350px;
    }
  }
`
