import { Swiper, SwiperSlide } from 'swiper/react'
import ArticleCard from '../ArticleCard'
import { styled } from 'styled-components'
import { FreeMode } from 'swiper/modules'
import { center, text_sm } from '../../styles/GlobalStyles'
import { Article } from '../../models/article'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css'

interface CarouselProps {
  data: Map<string, Article>
  slidesPerView?: number
  breakpoints?: {
    [key: number]: {
      slidesPerView: number
      spaceBetween: number
    }
  }
}
const CarouselPosts: React.FC<CarouselProps> = ({
  data,
  slidesPerView,
  breakpoints,
}) => {
  return (
    <SwiperContainer
      slidesPerView={slidesPerView}
      spaceBetween={20}
      breakpoints={breakpoints}
      freeMode={true}
      modules={[FreeMode]}
      touchRatio={0.5}
      className="mySwiper"
    >
      {Array.from(data.values()).map((item, index) => (
        <SwiperItem key={index}>
          <ArticleCard key={item.slug} article={item}></ArticleCard>
        </SwiperItem>
      ))}
    </SwiperContainer>
  )
}
const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 100%;
  z-index: 0;
`
const SwiperItem = styled(SwiperSlide)`
  ${text_sm}
  ${center}
  height: auto;
`
export default CarouselPosts
