import { ReactNode } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import { ToastContainer } from 'react-toastify'
import ButtonToTop from '../ButtonToTop/ButtonToTop'

interface LayoutProps {
  children: ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <ToastContainer />
      <Navbar />
      {children}
      <Footer />
      <ButtonToTop />
    </>
  )
}
export default Layout
