import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import {
  FaGithub,
  FaTelegram,
  FaLinkedin,
  FaInstagram,
  FaBars,
} from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import Text from '../Text'
import {
  Button,
  center,
  Container,
  graylight,
  growHover,
  white,
} from '../../styles/GlobalStyles'
import Logo from '../../assets/svg/logo'
import useDisabledScroll from '../../hooks/useDisableScroll'
import Search from './Search'

const Navbar: React.FC = () => {
  const navItem = ['about', 'projects', 'lifestyle', 'thoughts']
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [toggleMenu, setToggleMenu] = useState('hidden')
  const { setIsScroll } = useDisabledScroll()
  // Toggle MenuMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleMobileMenu = () => {
    if (isMobile) {
      setToggleMenu(toggleMenu === 'hidden' ? 'visible' : 'hidden')
      setIsScroll(toggleMenu === 'hidden' ? false : true)
    }
  }
  // End Toggle MenuMobile
  // Handle Search Query

  return (
    <>
      <NavbarWrapper>
        <NavPage to={''}>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </NavPage>
        <Search />
        <ItemWrapper className={toggleMenu}>
          <CloseButton onClick={toggleMobileMenu}>
            <MdClose />
          </CloseButton>
          {navItem.map((item, index) => (
            <NavPage
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={toggleMobileMenu}
              key={index}
              to={`/${item}`}
            >
              <NavItem>
                <Text>{`header.navigation.${index}.label`}</Text>
              </NavItem>
            </NavPage>
          ))}
          <SocialsSection>
            <LinkSocial href="https://www.instagram.com/" target="_blank">
              <FaInstagram />
            </LinkSocial>
            <LinkSocial href="https://github.com/" target="_blank">
              <FaGithub />
            </LinkSocial>
            <LinkSocial href="https://web.telegram.org/k/" target="_blank">
              <FaTelegram />
            </LinkSocial>
            <LinkSocial href="https://www.linkedin.com/" target="_blank">
              <FaLinkedin />
            </LinkSocial>
          </SocialsSection>
        </ItemWrapper>
        <MobileBar onClick={toggleMobileMenu}>
          <FaBars />
        </MobileBar>
      </NavbarWrapper>
    </>
  )
}
export default Navbar

const LinkSocial = styled.a`
  ${growHover}
  ${center}
`
const MobileBar = styled(Button)`
  ${center}
  font-size: 30px;
  color: ${graylight};
  display: none;
  &:hover {
    color: ${white};
  }
  @media (max-width: 768px) {
    display: flex;
  }
`
const CloseButton = styled(MobileBar)`
  position: absolute;
  font-size: 40px;
  top: 20px;
  right: 20px;
  color: ${graylight};
  z-index: 11;
  &:hover {
    color: ${white};
  }
`
const NavItem = styled.div`
  box-sizing: border-box;
  ${center}
  color: ${graylight};
  padding: 10px 20px;
  user-select: none;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 10px 6.5px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`
const NavPage = styled(NavLink)`
  text-decoration: none;
  ${NavItem} {
    color: ${graylight};
    ${growHover}
  }
  &.active ${NavItem} {
    color: ${white};
  }
`
const LogoWrapper = styled.div`
  user-select: none;
`
const NavbarWrapper = styled(Container)`
  ${center}
  flex-direction: row;
  position: relative;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 500px) {
    justify-content: space-between;
  }
`
const ItemWrapper = styled.div`
  ${center}
  transform: translateX(0px);
  @media (max-width: 768px) {
    &.hidden {
      transform: translateX(100%);
    }
    &.vivisible {
      transform: translateX(0px);
    }
    transition: all 0.5s;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    row-gap: 20px;
    width: 100vw;
    height: 100vh;
    background: black;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 36px;
    z-index: 10;
  }
`
const SocialsSection = styled(NavItem)`
  column-gap: 5px;
  ${center}
  @media (max-width: 768px) {
    padding-top: 50px;
  }
`
