import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DarkModeProvider } from './providers/DarkModeProvider';
import { ArticleProvider } from './providers/IndexedPostsProvider';
import { AuthorsProvider } from './providers/IndexedAuthorsProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <DarkModeProvider>
            <ArticleProvider>
                <AuthorsProvider>
                    <App />
                </AuthorsProvider>
            </ArticleProvider>
        </DarkModeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
