import { styled } from 'styled-components'
import {
  Container,
  flex_col,
  grayish,
  graylight,
  grayverylight,
  primary,
  text_lg,
  text_sm,
  UnderLine,
  white,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import Text from '../../components/Text'
import { HeroSection, PageDescription, PageHeading } from '../lifestyle'
import { t } from 'i18next'

const Projects: React.FC = () => {
  const projects = [
    {
      image: '/images/project1.png',
      name: 'dandelion labs',
    },
    {
      image: '/images/project2.png',
      name: 'superalgos.org',
    },
    {
      image: '/images/project3.png',
      name: 'evotek',
    },
    {
      image: '/images/project4.png',
      name: 'oxychain.earth',
    },
    {
      image: '/images/project2.png',
      name: 'superalgos.org',
    },
    {
      image: '/images/project2.png',
      name: 'superalgos.org',
    },
    {
      image: '/images/project2.png',
      name: 'superalgos.org',
    },
    {
      image: '/images/project2.png',
      name: 'superalgos.org',
    },
  ]
  return (
    <Transition>
      <PageContainer>
        <HeroSection>
          <PageHeading as={'h2'}>{t('projects.heading')}</PageHeading>
          <PageDescription>{'projects.description'}</PageDescription>
          <UnderLine />
        </HeroSection>
        <ProjectsSection>
          {projects.map((project, index) => (
            <ProjectCard key={index}>
              <ProjectImage src={`${project.image}`} />
              <ProjectDescription>
                <ProjectName as={'h5'}>{project.name}</ProjectName>
                <ProjectDetails>
                  Dandelion Labs is a blockchain product and research agency
                  creating meaningful experiences to power the next generation
                  of decentralized commerce
                </ProjectDetails>
              </ProjectDescription>
            </ProjectCard>
          ))}
        </ProjectsSection>
      </PageContainer>
    </Transition>
  )
}
export default Projects

const PageContainer = styled(Container)`
  ${flex_col}
  row-gap: 20px;
`

const ProjectsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 50px;
  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ProjectName = styled(Text)`
  ${text_lg}
  color: ${grayish};
  transition: all 0.5s;
`
const ProjectDetails = styled(Text)`
  ${text_sm}
  color : ${grayverylight};
  transition: all 0.5s;
`
const ProjectImage = styled.img`
  width: 100%;
  object-fit: contain;
  filter: grayscale(100%);
`
const ProjectCard = styled.div`
  ${flex_col}
  padding:20px;
  row-gap: 20px;
  border: 1px solid ${graylight};
  transition: all 0.5s;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    border-color: ${primary};
    opacity: 1;
    transform: scale(1.05);
  }
  &:hover ${ProjectName} {
    color: ${white};
  }
  &:hover ${ProjectDetails} {
    color: ${graylight};
  }
  &:hover ${ProjectImage} {
    filter: unset;
  }
`
const ProjectDescription = styled.div`
  ${flex_col}
  row-gap: 10px;
  text-align: center;
`
