import { useContext } from 'react';
import { AuthorsContext } from '../providers/IndexedAuthorsProvider';

export const useAuthors = () => {
    const context = useContext(AuthorsContext);
    if (context === undefined) {
        throw new Error('useArticles must be used within an ArticleProvider');
    }
    return context;
};
