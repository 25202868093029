import { Container } from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import {
  Post,
  PostContent,
  PostDetails,
  PostHeading,
  PostPublished,
  PostSection,
  PostsList,
  PostThumbnail,
  PostTitle,
  ProfileActivity,
  ProfileBio,
  ProfileMainDetails,
  ProfileName,
  ProfileSection,
  ProfileSlogan,
  Section,
} from '../author'
import postThumbnail from '../../assets/img/author1.png'
import { useArticles } from '../../hooks/useIndexedPosts'

const Subcategory: React.FC = () => {
  const { articles } = useArticles()

  return (
    <Transition>
      <Container>
        <Section>
          <ProfileSection>
            <ProfileMainDetails>
              <ProfileName as={'h2'}>subcategory Name</ProfileName>
              <ProfileSlogan as={'h5'}>short description</ProfileSlogan>
              <ProfileActivity>Created date, posts</ProfileActivity>
            </ProfileMainDetails>
            <ProfileBio>description</ProfileBio>
          </ProfileSection>
          <PostSection>
            <PostHeading as={'h5'}>lastest posts</PostHeading>
            {articles ? (
              <PostsList>
                {Array.from(articles.values()).map((post, index) => (
                  <Post to={`/post/${post.slug}`} key={index}>
                    <PostThumbnail src={postThumbnail} />
                    <PostDetails>
                      <PostTitle as={'h5'}>{post.title}</PostTitle>
                      <PostContent>{post.content}</PostContent>
                      <PostPublished>{post.publishedDate}</PostPublished>
                    </PostDetails>
                  </Post>
                ))}
              </PostsList>
            ) : null}
          </PostSection>
        </Section>
      </Container>
    </Transition>
  )
}
export default Subcategory
