import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import AppRoutes from './routes/AppRoutes';
import './i18n/i18n';
import { useMemo } from 'react';
import { theme } from './styles/Theme';
import { useDarkMode } from './hooks/useDarkMode';

const helmetContext = {};

function App() {
    const { isDarkMode } = useDarkMode();
    const themeDarkMode = useMemo(() => theme(true), []);
    const themeLightMode = useMemo(() => theme(false), []);

    return (
        <Router>
            <ThemeProvider theme={isDarkMode ? themeDarkMode : themeLightMode}>
                <GlobalStyles />
                <HelmetProvider context={helmetContext}>
                    <Layout>
                        <AppRoutes />
                    </Layout>
                </HelmetProvider>
            </ThemeProvider>
        </Router>
    );
}

export default App;
