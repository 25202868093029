import { FaGithub, FaInstagram, FaLinkedin, FaTelegram } from 'react-icons/fa'
import { Container } from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import {
  ProfileActivity,
  ProfilePicture,
  ProfileBio,
  ProfileMainDetails,
  ProfileName,
  ProfileSection,
  ProfileSlogan,
  Section,
  ProfileSocials,
} from '../author'

const About: React.FC = () => {
  const temBio =
    "Leon Acosta is a seasoned entrepreneur and product visionary with a deep-rooted passion for harnessing technology to drive positive social and environmental impact. Leon's entrepreneurial journey is marked by a commitment to people and talent, and he believes in the power of community and education to create lasting change."
  const socialsIcon = [FaInstagram, FaGithub, FaTelegram, FaLinkedin]
  return (
    <Transition>
      <Container>
        <Section>
          <ProfileSection>
            <ProfilePicture src="/images/author1.png" />
            <ProfileMainDetails>
              <ProfileName as={'h2'}>leon acosta</ProfileName>
              <ProfileSlogan as={'h5'}>inspire, love, effect 🌱</ProfileSlogan>
              <ProfileActivity>
                since aug 30, 2024 | . 128 posts
              </ProfileActivity>
            </ProfileMainDetails>
            <ProfileSocials>
              {socialsIcon.map((Icon, index) => (
                <Icon key={index} />
              ))}
            </ProfileSocials>
            <ProfileBio>{temBio}</ProfileBio>
          </ProfileSection>
        </Section>
      </Container>
    </Transition>
  )
}
export default About
