import { Link } from 'react-router-dom'
import { Container, primary } from '../../styles/GlobalStyles'
import { styled } from 'styled-components'

const NotFoundPage: React.FC = () => {
  return (
    <Container>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <BackLink to="/">Go back to Home</BackLink>
    </Container>
  )
}

export default NotFoundPage

const BackLink = styled(Link)`
  color: ${primary};
`
