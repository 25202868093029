import { Link, useParams } from 'react-router-dom'
import {
  Container,
  flex_col,
  graylight,
  grayverylight,
  orbitronFont,
  primary,
  ralewayFont,
  text_lg,
  text_sm,
  text_xl,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import { useAuthors } from '../../hooks/useIndexedAuthors'
import { useEffect, useState } from 'react'
import { Author } from '../../models/author'
import { styled } from 'styled-components'
import Text from '../../components/Text'
import { useArticles } from '../../hooks/useIndexedPosts'
import { Article } from '../../models/article'
import { FaGithub, FaTelegram, FaLinkedin, FaInstagram } from 'react-icons/fa'
import postThumbnail from '../../assets/img/author1.png'
import { t } from 'i18next'

const AuthorPage: React.FC = () => {
  const { alias } = useParams<{ alias: string }>()
  const { authors } = useAuthors()
  const { articles } = useArticles()
  const [article, setArticle] = useState<Article[]>([])
  const [author, setAuthor] = useState<Author | undefined>()
  const [authorImage, setAuthorImage] = useState('')
  useEffect(() => {
    import(`../../author/${author?.profile_image}`)
      .then((res) => {
        setAuthorImage(res.default)
      })
      .catch((error) => {
        console.error('Error loading image:', error)
      })
  })
  const socialsIcon = [FaInstagram, FaGithub, FaTelegram, FaLinkedin]

  useEffect(() => {
    setAuthor(alias ? authors.get(alias) : undefined)
    const authorArticle: Article[] = Array.from(articles.values()).filter(
      (article) => {
        return article.authorAlias === alias
      }
    )
    setArticle(authorArticle)
  }, [authors])
  return (
    <Transition>
      <Container>
        <Section>
          <ProfileSection>
            {author ? (
              <>
                <ProfilePicture src={authorImage} />
                <ProfileMainDetails>
                  <ProfileName as={'h2'}>{author.name}</ProfileName>
                  <ProfileSlogan as={'h5'}>Author Slogan</ProfileSlogan>
                  <ProfileActivity>
                    {`Author join date | . ${article?.length} posts`}
                  </ProfileActivity>
                </ProfileMainDetails>
                <ProfileSocials>
                  {socialsIcon.map((Icon, index) => (
                    <SocialLink
                      key={index}
                      href={`${Object.values(author.socials)[index]}`}
                      target="_blank"
                    >
                      <Icon />
                    </SocialLink>
                  ))}
                </ProfileSocials>
                <ProfileBio>{author.bio}</ProfileBio>
              </>
            ) : null}
          </ProfileSection>
          <PostSection>
            <PostHeading as={'h5'}>{t('common.lastest-post')}</PostHeading>
            {article ? (
              <PostsList>
                {article.map((post, index) => (
                  <Post to={`/post/${post.slug}`} key={index}>
                    <PostThumbnail src={postThumbnail} />
                    <PostDetails>
                      <PostTitle as={'h5'}>{post.title}</PostTitle>
                      <PostContent>{post.content}</PostContent>
                      <PostPublished>{post.publishedDate}</PostPublished>
                    </PostDetails>
                  </Post>
                ))}
              </PostsList>
            ) : null}
          </PostSection>
        </Section>
      </Container>
    </Transition>
  )
}
export default AuthorPage

export const Section = styled.div`
  width: 50%;
  ${flex_col}
  row-gap: 30px;
  font-family: ${orbitronFont};
`
export const ProfileSection = styled.div`
  ${flex_col}
  row-gap: 10px;
`
export const ProfilePicture = styled.img`
  width: 60px;
  border-radius: 50%;
`
export const ProfileMainDetails = styled.div``
export const ProfileName = styled(Text)``
export const ProfileSlogan = styled(Text)`
  color: ${graylight};
`
export const ProfileActivity = styled(Text)`
  color: ${grayverylight};
  ${text_sm}
  letter-spacing: 0.1em;
  line-height: 2.5;
`
export const ProfileSocials = styled.div`
  color: ${graylight};
  display: flex;
  column-gap: 10px;
`
export const SocialLink = styled.a`
  ${text_lg}
`
export const ProfileBio = styled(Text)`
  ${ralewayFont}
  color: ${graylight};
`
export const PostSection = styled.div`
  ${flex_col}
  row-gap: 30px;
`
export const PostHeading = styled(Text)`
  color: ${grayverylight};
  ${text_xl}
`
export const PostsList = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 10px;
`
export const Post = styled(Link)`
  display: flex;
  padding: 20px;
  column-gap: 20px;
  border: 1px solid ${grayverylight};
  transition: all 0.5s;
  &:hover {
    border-color: ${primary};
  }
`
export const PostThumbnail = styled.img`
  width: 140px;
`
export const PostDetails = styled.div`
  ${flex_col}
  row-gap: 10px;
`
export const PostTitle = styled(Text)`
  color: ${graylight};
`
export const PostContent = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${grayverylight};
  ${ralewayFont}
`
export const PostPublished = styled(Text)`
  color: ${grayverylight};
  ${text_lg}
  font-weight: 600;
  ${ralewayFont}
`
