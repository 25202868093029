import { FaLongArrowAltRight } from 'react-icons/fa'
import { Button, graylight, primary, white } from '../../styles/GlobalStyles'
import { styled } from 'styled-components'
import { ReactNode } from 'react'
import { RotatingLines } from 'react-loader-spinner'

interface ButtonArrowProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  className?: string
  loading?: boolean
}
export const ButtonArrow: React.FC<ButtonArrowProps> = ({
  children,
  className,
  loading,
  ...props
}) => {
  return (
    <ButtonContain className={className} {...props}>
      {children}
      {!loading ? (
        <FaLongArrowAltRight />
      ) : (
        <RotatingLines
          visible={true}
          width="20"
          strokeColor="rgba(255, 255, 255, 0.5)"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
      )}
    </ButtonContain>
  )
}

const ButtonContain = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: rgba(31, 31, 31, 1);
  border: 1px solid transparent;
  color: ${graylight};
  &:hover {
    border-color: ${primary};
    color: ${white};
  }
  &:disabled {
    border-color: transparent;
  }
`
