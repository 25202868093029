import { Container } from '../../styles/GlobalStyles'
import Transition from '../../Transition'

const Thoughts: React.FC = () => {
  return (
    <Transition>
      <Container>Thoughts page</Container>
    </Transition>
  )
}
export default Thoughts
