import {
  Container,
  flex_col,
  grayish,
  graylight,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import { styled } from 'styled-components'
import Text from '../../components/Text'
import { useRef } from 'react'
import { motion } from 'framer-motion'
import { CoolComponent } from '../../components/AnimatedShapes/CoolComponent'
import { t } from 'i18next'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import Topic from './Topic'

const Home: React.FC = () => {
  //function scroll animation
  const homeRef = useRef<HTMLDivElement>(null)
  const cool_component = useRef<HTMLDivElement>(null)
  gsap.registerPlugin(useGSAP, ScrollTrigger)
  useGSAP(
    () => {
      if (homeRef.current) {
        const { offsetWidth, offsetHeight } = homeRef.current
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: homeRef.current,
            start: 'top top',
            end: 'bottom center',
            scrub: 5,
          },
        })
        gsap.set(cool_component.current, { x: offsetWidth - 800, y: 0 })
        tl.to(cool_component.current, {
          x: -800,
          y: offsetHeight * 0.3,
          duration: 2,
        })
          .to('.vr', { width: 150, height: 150, duration: 1 }, '<')
          .to(cool_component.current, {
            x: -300,
            y: offsetHeight - 400,
            duration: 1,
          })
          .to(cool_component.current, {
            x: offsetWidth - 800,
            y: offsetHeight - 500,
            duration: 2,
          })
          .to('.vr', { width: 320, height: 320, duration: 1 }, '<')
      }
    },
    { scope: homeRef }
  )
  //end function

  //change topic

  return (
    <Transition>
      <HomeContainer ref={homeRef}>
        <AnimationComponent ref={cool_component}>
          <CoolComponent />
        </AnimationComponent>
        <HeroSection>
          <HeroSectionLeft>
            <HeroTitle>{'home.hero.title'}</HeroTitle>
            <HeroSubtitle as={'h1'}>{t('home.hero.sub-title')}</HeroSubtitle>
            <HeroTagline>{'home.hero.tagline'}</HeroTagline>
          </HeroSectionLeft>
        </HeroSection>
        <Topic />
      </HomeContainer>
    </Transition>
  )
}

export default Home

const HomeContainer = styled(Container)`
  position: relative;
`
const HeroSection = styled.div`
  width: 55%;
  padding-top: 50px;
  padding-bottom: 100px;
  margin-bottom: 200px;
`
const HeroSectionLeft = styled.div`
  width: 100%;
  ${flex_col}
  row-gap: 10px;
`
const HeroTitle = styled(Text)`
  font-size: 30px;
  letter-spacing: 0.1em;
  color: ${grayish};
`
const HeroSubtitle = styled(Text)`
  width: 90%;
  font-size: 50px;
`
const HeroTagline = styled(Text)`
  letter-spacing: 0.1em;
  font-size: 20px;
  color: ${graylight};
`
const AnimationComponent = styled(motion.div)`
  position: absolute;
  height: 400px;
  width: 600px;
`
