import { styled } from 'styled-components'
import {
  Container,
  flex_col,
  graylight,
  grayverylight,
  text_xl,
  UnderLine,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import Text from '../../components/Text'
import { useArticles } from '../../hooks/useIndexedPosts'
import CarouselPosts from '../../components/CarouselPosts'
import { t } from 'i18next'

const LifeStyle: React.FC = () => {
  const { articles } = useArticles()

  return (
    <Transition>
      <PageContainer>
        <HeroSection>
          <PageHeading as={'h2'}>{t('lifestyle.heading')}</PageHeading>
          <PageDescription>{'lifestyle.description'}</PageDescription>
          <UnderLine />
        </HeroSection>
        <SimilarPostsSection>
          <SectionPost>
            <SectionTitle as={'h2'}>subcategory xyz</SectionTitle>
            <CarouselPosts
              data={articles}
              breakpoints={{
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
              }}
            ></CarouselPosts>
          </SectionPost>
          <SectionPost>
            <SectionTitle as={'h2'}>subcag keywordrap</SectionTitle>
            <CarouselPosts
              data={articles}
              breakpoints={{
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
              }}
            ></CarouselPosts>
          </SectionPost>
        </SimilarPostsSection>
      </PageContainer>
    </Transition>
  )
}
export default LifeStyle

const PageContainer = styled(Container)`
  ${flex_col}
  row-gap: 20px;
`
export const HeroSection = styled.div`
  width: 55%;
  ${flex_col}
  @media (max-width:768px) {
    width: 100%;
  }
`
export const PageHeading = styled(Text)``
export const PageDescription = styled(Text)`
  ${text_xl}
  color : ${graylight};
  letter-spacing: 0.1em;
  line-height: 1.75;
`
const SimilarPostsSection = styled.div`
  ${flex_col}
  row-gap: 50px;
`
const SectionPost = styled.div`
  ${flex_col}
  row-gap: 20px;
`
const SectionTitle = styled(Text)`
  color: ${grayverylight};
`
