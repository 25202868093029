import { RouteProps } from 'react-router-dom'
import About from '../pages/about'
import Home from '../pages/home'
import LifeStyle from '../pages/lifestyle'
import Thoughts from '../pages/thoughts'
import NotFoundPage from '../pages/404page'
import Contact from '../pages/contact'
import Projects from '../pages/projects'
import Post from '../pages/post'
import AuthorPage from '../pages/author'
import Subcategory from '../pages/subcategory'

const layoutRoutes: RouteProps[] = [
  {
    path: '',
    element: <Home />,
  },
  {
    path: 'about',
    element: <About />,
  },
  {
    path: 'projects',
    element: <Projects />,
  },
  {
    path: 'lifestyle',
    element: <LifeStyle />,
  },
  {
    path: 'thoughts',
    element: <Thoughts />,
  },
  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'post/:slug',
    element: <Post />,
  },
  {
    path: 'subcategory',
    element: <Subcategory />,
  },
  {
    path: 'author/:alias',
    element: <AuthorPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
export default layoutRoutes
