import { styled } from 'styled-components'
import {
  Container,
  flex_col,
  graylight,
  grayverylight,
  ralewayFont,
  UnderLine,
  white,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import Markdown from 'react-markdown'
import { Link, useParams } from 'react-router-dom'
import Text from '../../components/Text'
import { useEffect, useState } from 'react'
import { useArticles } from '../../hooks/useIndexedPosts'
import { Article } from '../../models/article'
import { Author } from '../../models/author'
import { useAuthors } from '../../hooks/useIndexedAuthors'
import SEO from '../../components/SEO'
const Post: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const { articles } = useArticles()
  const { authors } = useAuthors()
  const [article, setArticle] = useState<Article | undefined>()
  const [author, setAuthor] = useState<Author | undefined>()
  const [authorImage, setAuthorImage] = useState('')
  // Get article and author infomation
  useEffect(() => {
    setArticle(slug ? articles.get(slug) : undefined)
  }, [slug, articles])
  useEffect(() => {
    setAuthor(article ? authors.get(article.authorAlias) : undefined)
    console.log(article)
  }, [article])
  // Get author image
  useEffect(() => {
    import(`../../author/${author?.profile_image}`)
      .then((res) => {
        setAuthorImage(res.default)
      })
      .catch((error) => {
        console.error('Error loading image:', error)
      })
  })

  return (
    <>
      {article ? (
        <SEO title={article.title} description={article.content} />
      ) : null}
      <Transition>
        <Container>
          {article ? (
            <PostContainer>
              <PostHeading>
                <PostTitle as={'h2'}>{article.title}</PostTitle>
                <PostCategory>{article.categories}</PostCategory>
                <PostTags>{article.tags.join(', ')}</PostTags>
              </PostHeading>
              <PostAuthor>
                {author ? (
                  <>
                    <LinkAuthor to={`/author/${author.alias}`}>
                      <AuthorImage
                        src={authorImage}
                        alt="Author profile image"
                      ></AuthorImage>
                    </LinkAuthor>

                    <AuthorName>{author.name}</AuthorName>
                  </>
                ) : null}
                <PostPublished>{article.publishedDate}</PostPublished>
              </PostAuthor>
              <UnderLine />
              <Markdown>{article.content}</Markdown>
              <UnderLine />
            </PostContainer>
          ) : (
            <Text>Post not found</Text>
          )}
        </Container>
      </Transition>
    </>
  )
}
export default Post

const PostContainer = styled.div`
  width: 55%;
  ${flex_col}
  row-gap: 10px;
`
const PostHeading = styled.div`
  ${flex_col}
`
const PostTitle = styled(Text)`
  color: ${white};
  line-height: 45px;
`
const PostCategory = styled(Text)`
  color: ${graylight};
  line-height: 34px;
`
const PostTags = styled(Text)`
  color: ${grayverylight};
  line-height: 34px;
`
const PostPublished = styled(Text)``
const PostAuthor = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: auto 1fr;
  column-gap: 30px;
  align-items: center;
  color: ${graylight};
  font-family: ${ralewayFont};
`
const LinkAuthor = styled(Link)`
  grid-row: span 2 / span 2;
`
const AuthorImage = styled.img`
  width: 60px;
  border-radius: 50%;
`
const AuthorName = styled(Text)`
  font-weight: 600;
`
