import { createContext, ReactNode } from 'react'
import { Article } from '../models/article'
import dataPosts from '../data/searchInformation.json'

export const ArticleContext = createContext<ArticleContextType | undefined>(
  undefined
)
interface ArticleContextType {
  articles: Map<string, Article>
}
interface ArticleProviderProps {
  children: ReactNode
}
export const ArticleProvider: React.FC<ArticleProviderProps> = ({
  children,
}) => {
  const articles: Map<string, Article> = new Map(Object.entries(dataPosts))
  return (
    <ArticleContext.Provider value={{ articles }}>
      {children}
    </ArticleContext.Provider>
  )
}
