import { styled } from 'styled-components'
import CarouselPosts from '../../components/CarouselPosts'
import {
  flex_col,
  grayish,
  graylight,
  grayverylight,
  ralewayFont,
  text_lg,
  white,
} from '../../styles/GlobalStyles'
import { motion } from 'framer-motion'
import { t } from 'i18next'
import Text from '../../components/Text'
import { useArticles } from '../../hooks/useIndexedPosts'
import { useEffect, useState } from 'react'

const Topic: React.FC = () => {
  //get articles
  const { articles } = useArticles()
  // selected topic
  const [selectedTopic, setSelectedTopic] = useState(0)
  // auto next topic
  useEffect(() => {
    const intervalId = setInterval(
      () => setSelectedTopic((index) => (index < 4 ? index + 1 : 0)),
      3000 // every 3 seconds
    )
    return () => clearTimeout(intervalId)
  }, [selectedTopic])
  // end auto next topic
  return (
    <TopicSection>
      <TopicList>
        {Array.from({ length: 5 }).map((_, index) => (
          <TopicItem
            key={index}
            onClick={() => {
              setSelectedTopic(index)
            }}
          >
            <TopicText
              as={'h5'}
              className={`${index === selectedTopic ? `active` : ``}`}
            >
              {t(`home.topic.menu.${index}.title`)}
            </TopicText>
          </TopicItem>
        ))}
      </TopicList>
      <TopicContent>
        <TopicDetails
          key={selectedTopic}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <TopicTitle as={'h3'}>
            {t(`home.topic.menu.${selectedTopic}.title`)}
          </TopicTitle>
          <TopicDes>{`home.topic.menu.${selectedTopic}.description`}</TopicDes>
        </TopicDetails>
        <PostSection>
          <CarouselPosts
            data={articles}
            breakpoints={{
              1280: {
                slidesPerView: 2.8,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 1.8,
                spaceBetween: 10,
              },
              320: {
                slidesPerView: 1.1,
                spaceBetween: 10,
              },
            }}
          ></CarouselPosts>
        </PostSection>
      </TopicContent>
    </TopicSection>
  )
}
export default Topic
const TopicSection = styled.div`
  margin-bottom: 200px;
`
const TopicList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  margin: 30px 0;
  border-top: 1px solid ${grayverylight};
  border-bottom: 1px solid ${grayverylight};
`
const TopicItem = styled.div`
  width: 13%;
  cursor: pointer;
`
const TopicText = styled(Text)`
  color: ${graylight};
  transition: all 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  &.active {
    color: ${white};
    font-weight: 700;
  }
  &:hover {
    color: ${white};
  }
`
const TopicContent = styled.div`
  display: flex;
  padding: 10px 0;
  position: relative;
`
const TopicDetails = styled(motion.div)`
  width: 45%;
  ${flex_col}
  row-gap: 30px;
`
const TopicTitle = styled(Text)``
const TopicDes = styled(Text)`
  ${ralewayFont}
  color: ${grayish};
  ${text_lg}
  line-height: 1.25;
  transition: all 1s;
`
const PostSection = styled.div`
  width: 55%;
  transform: translateX(95px);
  @media (max-width: 1024px) {
    transform: translateX(15px);
  }
`
